import { Injectable } from '@angular/core';
import { AlertController, ToastController } from '@ionic/angular';
import * as constants from '../constants';
import { UtilsService } from '../services/utils.service';
declare var annyang: any;
declare var Peer: any;

@Injectable({
  providedIn: 'root'
})
export class PeerjsService {
  // localVideoComponent = document.getElementById('local-video') as HTMLVideoElement;
  // remoteVideoComponent = document.getElementById('remote-video') as HTMLVideoElement;
  remoteAudioComponent = document.getElementById('remote-audio') as HTMLVideoElement;
  connectButton = document.getElementById('connect-button');
  callButton = document.getElementById('call-button');
  answerButton = document.getElementById('answer-button');
  hangupButton = document.getElementById('hungup-button');
  inputPeer = document.getElementById('input-peer');
  // hangupButton.disabled = false
  // answerButton.disabled = true;

  constructor(public alertCtrl: AlertController, private toastController: ToastController, public utils: UtilsService) {
    //this.setLocalStream();
  }

  mediaConstraints = {
    audio: true,
    video: false
    // video: { width: 1280, height: 720 },
  };

  host = 'nodejsawsdqagro.duckdns.org';
  port = 3333;
  /*
  const host = 'localhost'
  const port = '3000'*/
  status = 'Unregistered';
  callState = constants.STATE_HUNG;
  recognition;
  extensions;
  alert;
  audio;
  connectInterval;
  ringInterval;
  peerId;
  conn;
  peer;
  callPeer;
  localStream;
  remoteStream;
  speech = false;
  annyang = annyang;
  speechRecognition;
  speechGrammarList;
  intervalRestart;
  callbtn;
  toast;
  words = ['llamar', 'llama', 'yama', 'ama', 'hama'];
  tones = 0;
  maxTones = 6;
  hangupCause = 'busy';
  callBtnPosition = 'up';

    iceServers: [
      { urls: 'stun:stun.l.google.com:19302' },
      { urls: 'stun:stun1.l.google.com:19302' },
      { urls: 'stun:stun2.l.google.com:19302' },
      { urls: 'stun:stun3.l.google.com:19302' },
      { urls: 'stun:stun4.l.google.com:19302' },
      {
        urls: 'turn:numb.viagenie.ca',
        credential: 'muazkh',
        username: 'webrtc@live.com'
      }
    ]

  /*
  STUN = {
    urls: 'stun:stun.l.google.com:19302'
 };
 
 TURN = {
    urls: 'turn:turn.bistri.com:80',
    credential: 'homeo',
    username: 'homeo'
 };
 
 iceServers = {
    iceServers: [this.STUN, this.TURN]
 };*/
  
  destinys = [
    {
      ext: 1,
      destiny: 3
    },
    {
      ext: 2,
      destiny: 4
    },
    {
      ext: 3,
      destiny: 1
    },
    {
      ext: 4,
      destiny: 2
    },
  ];

  start() {
    this.register();
    this.connectInterval = setInterval(() => {
      console.error('checking, is registered ' + (this.status == 'Registered'));

      if (this.status != 'Registered') {
        this.register();
      }
      console.log('sohwing standby ' + this.utils.showingStandBy)
      if (this.utils.showingStandBy) {
        if (this.speech) {
          this.stopVoiceRecognition();
        }
      } /*else {
        console.log('speech ' + this.speech)
        if (!this.speech) {
          this.startVoiceRecognition();
        }
      }*/
    }, 5000);
  }


  register() {
    console.log('registering ... ' + this.peerId);
    this.peer = new Peer(this.peerId, {key: '52hhtusf1t0rudi'}, {
      host: this.host,
      port: this.port,
      secure: true,
      path: '/peerjs',
      debug: 3,
      config: {
        iceServers: this.iceServers
      }
    });
    this.peer.on('open', (id) => {
      // this.connectButton.disabled = true
      this.status = 'Registered';
      console.log('My peer ID is: ' + id + ' status ' + this.status);
      this.startVoiceRecognition();
    });
    this.peer.on('connection', (conn) => {
      alert('on connection');
      this.conn = conn;
    });
    this.peer.on('close', () => {
      this.status = 'Unregistered';
      console.log('on close');
    });
    this.peer.on('disconnected', () => {
      this.status = 'Disconnected';
      console.error('on disconnected');
      /*this.hangupButton.disabled = true
      this.callButton.disabled = false
      this.connectButton.disabled = false*/
    });
    this.peer.on('destroyed', () => {
      this.status = 'Destroyed';
      console.error('on destroyed');
      /*this.hangupButton.disabled = true
      this.callButton.disabled = false
      this.connectButton.disabled = false*/
    });
    this.setupAnswer();
  }

  initStartRecognition() {
    const words = ['dq agro']; // 'agro', 'bq agro', 'recuadro', 'becuadro', 'becocuadro'];
    const grammar = '#JSGF V1.0; grammar words; public <word> = ' + words.join(' | ') + ' ;';

    this.speechRecognition = new (window['SpeechRecognition'] || window['webkitSpeechRecognition']);
    // tslint:disable-next-line: new-parens
    const speechGrammarList = window['webkitSpeechGrammarList'];
    const speechRecognitionList = new speechGrammarList(); // || webkitSpeechGrammarList
    speechRecognitionList.addFromString(grammar, 1);
    this.speechRecognition.continuous = true;
    this.speechRecognition.lang = 'es-ES';
    this.speechRecognition.interimResults = true;
    this.speechRecognition.maxAlternatives = 10;
  }

  setCall(destinyPeer) {
    if (this.callState != constants.STATE_CALLING) {
      this.callState = constants.STATE_CALLING;
      this.callbtn.className = 'btn-call-calling';
     

      alert('connecting to ' + destinyPeer)

      this.conn = this.peer.connect(destinyPeer);
      this.setConnectionEvents(this.conn);

      this.callPeer = this.peer.call(destinyPeer, this.localStream);
      this.setCallEvents(this.callPeer);
      this.presentToastType('Llamando...', 'success', 5000);

      setTimeout(() => {
        this.playOutboundRing();
      }, 10);

    }
  }

  answerCall() {
    console.log('Answering...');
    this.callPeer.answer(this.localStream);
  }

  totalExtensions() {
    let total = 0;
    this.extensions.forEach(ext => {
      if (ext.extension != this.peerId) {
        total++;
      }
    });
    return total;
  }

  getOtherExtension(): any {
    let destiny;
    this.extensions.forEach(ext => {
      console.error('soy ' + ext.extension + ' otra ' + this.peerId);
      if (ext.extension != this.peerId) {
        destiny = ext.extension;
        console.error('devuelvo ' + ext.extension);
        return ext.extension;
      }
    });
    return destiny;
  }

  dismissToast() {
    try {
      this.toast.dismiss();
    } catch (error) {

    }
  }

  dismissAlert() {
    try {
      this.alert.dismiss();
    } catch (error) {

    }
  }

  async presentAlertIncoming(peerdisplayname) {
    console.log('targetUser ' + peerdisplayname);
    this.alert = await this.alertCtrl.create({
      cssClass: 'alertCustomCss',
      message: 'Llamada entrante de ' + peerdisplayname,
      backdropDismiss: false,
      buttons: [
        {
          text: 'Rechazar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
            this.hangup();
          }
        }, {
          text: 'Aceptar',
          handler: () => {
            console.log('Confirm Okay');
            this.answerCall();
          }
        }
      ]
    });
    await this.alert.present();
  }

  // CALL
  setLocalStream() {
    try {
      // this.hangupButton.disabled = true
      const getUserMedia = navigator.getUserMedia || navigator['webkitGetUserMedia'] || navigator['mozGetUserMedia'];
      getUserMedia(this.mediaConstraints, (stream) => {
        this.localStream = stream;
        // this.localVideoComponent.srcObject = stream;
        // var call = peer.call(destinyPeer, stream);
        console.log('1 on remote stream ');
      }, (err) => {
        console.log('Failed to get local stream', err);
      });
    } catch (error) {
      console.error(error);
    }
  }

  setupAnswer() {
    try {
      const getUserMedia = navigator.getUserMedia || navigator['webkitGetUserMedia'] || navigator['mozGetUserMedia'];
      this.peer.on('call', (call) => {
        // this.answerButton.disabled = false;
        // this.hangupButton.disabled = false
        console.log('on call from ' + call.peer);
        this.onCall(call);

        getUserMedia(this.mediaConstraints, (stream) => {
          // call.answer(stream); // Answer the call with an A/V stream.
          this.setCallEvents(this.callPeer);
        }, (err) => {
          console.log('Failed to get local stream', err);
        });
      });
    } catch (error) {
      console.error(error)

    }
  }

  onCall(call) {
    this.stopVoiceRecognition();
    this.playIncomingRing();
    this.callPeer = call;
    this.presentAlertIncoming(call.peer);
    this.setConnectionEvents(this.conn);
  }

  setConnectionEvents(conn) {
    console.log('setting connection events...');

    conn.on('open', () => {
     alert('on open connection');
      conn.on('data', (data) => {
        console.log('Received', data);
      });
    });
    conn.on('close', () => {
      console.error('on close call ' + this.hangupCause);
      this.remoteAudioComponent.srcObject = null;
      this.callState = constants.STATE_HUNG;
      this.callbtn.className = 'btn-call';

      this.presentToastType('Llamada colgada', 'danger', 2000);

      this.stopRing();
      this.dismissAlert();

      setTimeout(() => {
        this.startVoiceRecognition();
      }, 2000);
    });
  }

  itsMe() {
    return this.callPeer.peer == this.peerId;
  }

  setCallEvents(call) {
    call.on('stream', (remoteStream) => {
      this.onAnswerCall(remoteStream);
    });
    call.on('close', () => {
      console.error('call closed ');
    });
  }

  onAnswerCall(remoteStream) {
    this.callState = constants.STATE_ONLINE;
    this.stopVoiceRecognition();
    console.log('2 on remotestream ' + remoteStream);
    this.remoteAudioComponent.srcObject = remoteStream;
    this.stopRing();
  }

  stopRing() {
    try {
      clearInterval(this.ringInterval);
      if (this.audio) {
        this.audio.pause();
        this.audio.currentTime = 0;
      }
    } catch (error) {

    }
  }

  playIncomingRing() {
    const src = '../../assets/sounds/Ring1.mp3';
    this.audio = new Audio(src);
    this.audio.play();
    this.ringInterval = setInterval(() => {
      this.audio.play();
    }, 2000);
  }

  playOutboundRing() {
    console.log('playing interval');
    const src = '../../assets/sounds/RingOutbound.mp3';
    this.audio = new Audio(src);
    this.audio.play();

    if (this.ringInterval) {
      clearInterval(this.ringInterval);
    }

    this.ringInterval = setInterval(() => {
      console.log('ringing interval');

      this.tones++;

      if (this.tones >= this.maxTones) {
        this.hangupCause = 'busy';
        this.tones = 0;
        this.hangup();
      } else {
        this.audio.play();
      }
    }, 4000);
  }

  hangup() {
    this.callState = constants.STATE_HUNG;
    this.callbtn.className = 'btn-call';

    this.stopRing();
    this.callPeer.close();
    this.conn.close();
    this.presentToastType('Llamada colgada', 'danger', 2000);
  }

  async presentToastType(message, color, time) {
    try {
      this.toast.dismiss();
    } catch (e) { }

    this.toast = await this.toastController.create({
      message: message,
      color: color,
      duration: time,
      position: (this.callBtnPosition == 'bottom'? 'top':'bottom')
    });
    this.toast.present();
  }

  startVoiceRecognition() {

    if ((this.peerId == '1' || this.peerId == '2') && this.status == 'Registered') {
      this.dismissAlert();
      console.error('starting voice recognition...');

      this.speechRecognition.start();
      this.intervalRestart = setInterval(() => {
        if (!this.speech && this.callState == constants.STATE_HUNG) {
          this.speechRecognition.start();
        }
      }, 5000);
      this.speechRecognition.onstart = () => {
        // document.getElementById('talk').style.display = 'block';
        this.dismissAlert();
        console.error('started voice recognition...');
        this.speech = true;
        this.talk();
      };
      this.speechRecognition.onspeechstart = () => {
        // document.getElementById('talk').style.display = 'block';
        this.dismissAlert();
        console.log('starting speech recognition...');

        this.talk();
      };
      this.speechRecognition.onsoundstart = () => {
        // document.getElementById('talk').style.display = 'block';
        console.log('on sound start...');
        this.speech = true;
        document.getElementById('micro').style.display = 'block';
        // document.getElementById('microColor').style['--background'] = 'green';
      };
      this.speechRecognition.onaudioend = () => {
        // document.getElementById('talk').style.display = 'none';
        console.log('on sound end...');
        this.speech = false;
        document.getElementById('micro').style.display = 'none';
        this.presentToastType('Reconociendo voz, espere por favor...', 'primary', 5000);

      };
      this.speechRecognition.onresult = (event) => {
        const result = event.results[0][0].transcript as string;
        result.toLocaleLowerCase();

        console.log('result here ' + result + ' confidence ' + event.results[0][0].confidence + ' ismarc ' + this.isMatch(result));


        if (this.isMatch(result)) {
          const destiny = this.getDestiny();
          console.log('destiny soy ' + destiny);
          this.setCall(destiny);
        }
      };
      this.speechRecognition.onspeechend = () => {
        console.error('on speech end');
      };
      this.speechRecognition.onnomatch = (event) => {
        console.log('no match');
      };
      this.speechRecognition.onerror = (event) => {
      };
    }


  }

 
  getDestiny() {
    var destiny;
    this.destinys.forEach(item => {
      //console.log('soy  ' + item.ext + ' mi id ' + this.response.configuration.extension_id);
      if (item.ext == this.peerId) {
        console.log('igual  ');
        destiny = item.destiny;
        return false;
      }
    });
    return destiny;
  }

  talk() {
    this.speech = true;
    if (document.getElementById('micro')) {
      document.getElementById('micro').style.display = 'block';

    }
    this.presentToastType('Hable ahora', 'primary', 50000);

  }

  isMatch(result): boolean {
    let isMatch = false;
    this.words.forEach(word => {
      // console.log('result ' + result + ' include ' + word + ' include?' + (result.includes(word)));
      if (result.includes(word)) {
        isMatch = true;
        return;
      }
    });
    return isMatch;
  }

  stopVoiceRecognition() {
    if (this.speechRecognition) {
      this.speechRecognition.stop();
    }
    clearInterval(this.intervalRestart);

    this.dismissToast();
    // this.dismissAlert();
  }

  resumeVoiceRecognition() {
    // annyang.resume();
    if (this.speechRecognition && this.peerId == '1') {
      this.speechRecognition.start();
    }
  }

}
