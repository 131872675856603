import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  isPortrait = true;
  //isLandscape = true;
  showingStandBy = false;
  showStandby = true;
  speech = false;

  constructor(public router: Router) {
  }

}
