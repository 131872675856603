import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import * as constants from '../constants';
import { RestService } from '../services/rest.service';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class StatisticsService {

  constructor(private httpClient: HttpClient, public rest: RestService, private toastController: ToastController) {

  }

  createStatsJson() {
    this.rest.stats = [];
    this.rest.statsTouchJSONObject = [];
    this.rest.statsCallWaiterJSONObject = [];
    this.rest.statsBillCardJSONObject = [];
    this.rest.statsBillCashJSONObject = [];
    this.rest.statsAddProductJSONObject = [];
    this.rest.statsOrderJSONObject = [];
    this.rest.statsEnterAppJSONObject = [];
  }

  addStatGlobal(action) {
    const dateTime = this.getDate();

    let newStat = {
      mac: this.rest.mac,
      id_establishment: this.rest.response.configuration.id_establishment,
      id_menu_app: this.rest.response.configuration.id,
      id_language: this.rest.usedLang,
      id_stat_action: action,
      // id_menu_level: menuLevel,
      // id_relative: id_product,
      amount: 1,
      created_at: dateTime,
    };

    console.log('ADDING STAT GLOBAL')

    this.add(action, newStat);
  }

  addStat(action, menuLevel, id_product, total) {
    const dateTime = this.getDate();

    let newStat = {
      mac: this.rest.mac,
      id_establishment: this.rest.response.configuration.id_establishment,
      id_menu_app: this.rest.response.configuration.id,
      id_language: this.rest.usedLang,
      id_stat_action: action,
      id_menu_level: menuLevel,
      id_relative: id_product,
      amount: total,
      created_at: dateTime,
    };

    this.add(action, newStat);
  }

  add(action, newStat) {
    if (action == constants.ACTION_TOUCH) {
      this.rest.statsTouchJSONObject.push(newStat);
    } else if (action == constants.ACTION_CALL_WAITER) {
      this.rest.statsCallWaiterJSONObject.push(newStat);
    } else if (action == constants.ACTION_BILL_CARD) {
      this.rest.statsBillCardJSONObject.push(newStat);
    } else if (action == constants.ACTION_BILL_CASH) {
      this.rest.statsBillCashJSONObject.push(newStat);
    } else if (action == constants.ACTION_ADD_PRODUCT) {
      this.rest.statsAddProductJSONObject.push(newStat);
    } else if (action == constants.ACTION_ORDER) {
      this.rest.statsOrderJSONObject.push(newStat);
    } else if (action == constants.ACTION_ENTER_APP) {
      this.rest.statsEnterAppJSONObject.push(newStat);
    }
  }

  getDate() {
    let date = new Date();
    const hours = ("0" + date.getHours()).slice(-2);
    const minutes = ("0" + date.getMinutes()).slice(-2);
    const seconds = ("0" + date.getSeconds()).slice(-2);
    const dateTime = date.toISOString().substring(0, 10) + ' ' + hours + ':' + minutes + ':' + seconds;
    return dateTime;
  }

  getStatsForSend() {
    let tempStats: any;
    if (this.rest.statsTouchJSONObject.length > 0) {
      tempStats = {
        'touch':
          this.rest.statsTouchJSONObject
      };
    }
    if (this.rest.statsCallWaiterJSONObject.length > 0) {
      tempStats = {
        'call_waiter':
          this.rest.statsCallWaiterJSONObject
      };
    }
    if (this.rest.statsBillCardJSONObject.length > 0) {
      tempStats = {
        'bill_card':
          this.rest.statsBillCardJSONObject
      };
    }
    if (this.rest.statsBillCashJSONObject.length > 0) {
      tempStats = {
        'bill_cash':
          this.rest.statsBillCashJSONObject
      };
    }
    if (this.rest.statsAddProductJSONObject.length > 0) {
      tempStats = {
        'add_product':
          this.rest.statsAddProductJSONObject
      };
    }
    if (this.rest.statsOrderJSONObject.length > 0) {
      tempStats = {
        'order':
          this.rest.statsOrderJSONObject
      };
    }
    if (this.rest.statsEnterAppJSONObject.length > 0) {
      tempStats = {
        'enter_app':
          this.rest.statsEnterAppJSONObject
      };
    }
    return tempStats;
  }

  public async postStatsToCMS() {
    return new Promise(resolve => {
      this.httpClient.post(constants.STATSURL, this.getStatsForSend()).subscribe(data => {
        console.log('RESPONSE STAT ' + JSON.stringify(data) + 'code ' + data['code']);

        this.createStatsJson();

        if (data['code'] === '200') {
          console.log('response 200 ok');

          //this.rest.response = data['response'];
        } else {
          //console.log( data['message']);

          //this.rest.presentToast(data['message']);
        }
        resolve(data);
      }, err => {
        console.log('error ' + JSON.stringify(err));
        console.log('message ' + err['message']);
        //this.rest.presentToast( err['message']);
      });
    });
  }
}
