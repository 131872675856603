import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import * as constants from '../constants';

@Injectable({
  providedIn: 'root'
})
export class GlobalsService {
  

  constructor() { }

}
