import { STATE_HUNG } from './../constants';
import { RestService } from './rest.service';
import { AlertController } from '@ionic/angular';
import { Injectable } from '@angular/core';
declare var webphone_api: any;
import * as constants from '../constants';
import { isNull } from 'util';
import * as JsSIP from 'jssip';
// import { Web } from 'sip.js';

@Injectable({
    providedIn: 'root'
})
export class WebphoneService {
    // ipAsterisk = '192.168.1.115';
    // ipAsterisk = 'sohosantacatalina.mywire.org:7061';
    // ipAsterisk = 'sohosantacatalina.mywire.org:7060';
    ipAsterisk = 'sohosantacatalina.mywire.org';
    username = '4000';
    password = 'Iurban';
    userDestiny = '4000';

    // ipAsterisk = '212.225.176.67:7061';
    // ipAsterisk = '192.168.0.110';
    // ipAsterisk = 'voip.mizu-voip.com';
    /*username = '2010';
    password = '2010';
    userDestiny = '2011';*/

    /*ipAsterisk = 'voip.mizu-voip.com';
    username = 'webphonetest1';
    password = 'webphonetest1';
    userDestiny = 'webphonetest2';*/



    callState = constants.STATE_HUNG;
    consts = constants;
    REG_STATE_REGISTERING = 'REG_STATE_REGISTERING';
    REG_STATE_REGISTERED = 'REG_STATE_REGISTERED';
    REG_STATE_UNREGISTERED = 'REG_STATE_UNREGISTERED';
    REG_STATE_FAILED = 'REG_STATE_FAILED';

    APP_STATE_LOADED = 'loaded';
    APP_STATE_STARTED = 'started';

    regState = '';
    appState = '';
    status = '';

    // serveraddress = '';

    destination = '';

    constructor(public alertCtrl: AlertController) {
        let btnHangup = (document.getElementById('btn_hangup') as HTMLButtonElement);
        let incomingCallLayout = document.getElementById('incoming_call_layout');

        if (btnHangup != undefined) {
            btnHangup.disabled = true;
        }

        if (incomingCallLayout != undefined) {
            incomingCallLayout.style.display = 'none';
        }

        // Wait until the webphone is loaded, before calling any API functions
        // if automatic start is required, then webphone_api.start() should be called "onLoaded" event like this:
        // webphone_api.onAppStateChange(function (app_state)
        // {
        //    if (app_state === 'laoded')
        //    {
        //      webphone_api.start();
        //    }
        // });
    }

    initWebPhone() {
        webphone_api.parameters['autostart'] = 0;
        webphone_api.parameters['forcereregister'] = 2;

        webphone_api.onAppStateChange((appState) => {
            this.appState = appState;
            if (appState === 'loaded') {


            } else if (appState === 'started') {
                // document.getElementById('events').innerHTML = 'EVENT, Started';
                // webphone_api.unregister();
                // webphone_api.register();
                // alert('ON APP STATE CHANGED ' + 'appstate ' + this.appState + ' ' + (this.appState === this.APP_STATE_STARTED));

            } else if (appState === 'stopped') {
                // document.getElementById('events').innerHTML = 'EVENT, Stopped';
            }
        });

        webphone_api.onRegStateChange((regState) => {
            // alert('WEBPHONE REG_STATE ' + regState);
            if (regState === 'registered') {
                this.regState = 'registered';
                // alert('WEBPHONE REGISTERED');
                //console.log('WEBPHONE REGISTERED');
                // document.getElementById('events').innerHTML = 'EVENT, Registered.';
            } else if (regState === 'unregistered') {
                this.regState = 'unregistered';
                //console.log('WEBPHONE UNREGISTERED');
                // document.getElementById('events').innerHTML = 'EVENT, UnRegistered.';
            } else if (regState === 'failed') {
                this.regState = 'failed';
                //console.log('WEBPHONE FAILED');
                // document.getElementById('events').innerHTML = 'ERROR, Register failed.';
            }
        });

        webphone_api.onEvent((type, evt) => {
            if (type === 'event') {
                this.ProcessNotifications(evt);
                // alert('on event ' + evt);
            }
        });

        webphone_api.onCallStateChange((event, direction, peername, peerdisplayname, line, callid) => {
            // document.getElementById('events').innerHTML = 'EVENT, Call ' + event;

            if (event === 'setup') {
                // alert('CALLING ' + direction);

                // (document.getElementById('btn_hangup') as HTMLButtonElement).disabled = false;
                if (direction == 1) {
                    // means it's outgoing call
                    this.callState = this.consts.STATE_CALLING;

                } else if (direction == 2) {
                    // means it's icoming call

                    // this.presentAlertIncoming(peerdisplayname);
                    // webphone_api.presentAlertIncomingJS(peerdisplayname);
                }
                // document.getElementById('events').innerHTML = 'EVENT, Call setup';
            } else if (event === 'disconnected') {
                // detecting the end of a call, even if it wasn't successfull
                // alert('on call state change disconnected');

                this.callState = this.consts.STATE_HUNG;
            } else if (event === 'connected') {
                this.callState = constants.STATE_ONLINE;
            }
        });
    }

    /** parse received notifications*/
    // var ringingNumber = '';
    ProcessNotifications(not) {
        try {
            if (isNull(not) || not.length < 1) { return; }

            not = this.Trim(not);

            var type = '';
            var line = '';
            var notifyword = '';

            //STATUS,1,Ringing,8888,9999,2,8888
            var pos = not.indexOf(',');
            if (pos > 0) {
                type = this.Trim(not.substring(0, pos));
                not = this.Trim(not.substring(pos + 1));
            }

            // get line
            pos = not.indexOf(',');
            if (pos > 0) {
                line = this.Trim(not.substring(0, pos));
                notifyword = this.Trim(not.substring(pos + 1));
            } else {
                notifyword = not;
            }

            var notifywordcontent = '';

            // clear junk of notifyword's end
            pos = notifyword.indexOf(',');
            if (pos > 0) {
                notifywordcontent = this.Trim(notifyword.substring(pos + 1));
                notifyword = this.Trim(notifyword.substring(0, pos));
            }
            pos = notifyword.indexOf('[');
            if (pos > 0) { notifyword = this.Trim(notifyword.substring(0, pos)); }
            pos = notifywordcontent.indexOf('[');
            if (pos > 0) { notifywordcontent = this.Trim(notifywordcontent.substring(0, pos)); }



            // handle incoming call
            if (type === 'STATUS') {
                // you can futher process/use the received status event messages
                // check the documentation for more details
            }

            this.DisplayStatus(type + ',' + notifyword);

        } catch (err) { console.error('error ' + err); }
    }

    status_e = null;
    DisplayStatus(msg_orig) {
        console.error('on display status ' + msg_orig)
        try {
            var msg = msg_orig;
            if (isNull(this.status_e)) { this.status_e = document.getElementById('status'); }

            if (isNull(msg) || msg.length < 1) { msg = '&nbsp;'; }
            if (msg.toLowerCase().indexOf('subscribe') >= 0) { return; }

            if (msg.indexOf('ERROR') >= 0) {
                msg = msg.replace('ERROR', '');
                msg = this.Trim(msg);

                if (msg.indexOf(',') === 0) {
                    msg = msg.replace(',', '');
                    msg = this.Trim(msg);
                }

                // msg = '<span style="color:red;">' + msg + '</span>';
            }
            else if (msg.indexOf('WARNING') >= 0) {
                msg = msg.replace('WARNING', '');
                msg = this.Trim(msg);

                if (msg.indexOf(',') === 0) {
                    msg = msg.replace(',', '');
                    msg = this.Trim(msg);
                }

                // msg = '<span style="color: #ff6600;">' + msg + '</span>';
            }
            else if (msg.indexOf('STATUS') >= 0 || msg.indexOf('EVENT') >= 0) {
                msg = msg.replace('STATUS', '');
                msg = msg.replace('EVENT', '');
                msg = this.Trim(msg);

                if (msg.indexOf(',') === 0) {
                    msg = msg.replace(',', '');
                    msg = this.Trim(msg);
                }
            }

            console.error('on event aqui ' + msg);

            this.status = msg;

            // this.status_e.innerHTML = msg;
            // this.PutToDebugLog(2, 'NOT: ' + msg_orig);

        } catch (err) { this.PutToDebugLogException(2, 'live_demo: DisplayStatus', err); }
    }

    PutToDebugLogException(level, msg, err) { this.PutToDebugLog(level, 'ERROR,' + msg + ' ' + err); }

    PutToDebugLog(level, msg) {
        if (msg.indexOf("ERROR") > -1) {
            try { console.error(msg); } catch (e1) { }
        } else {
            try { console.log(msg); } catch (e2) { }
        }
    }

    Trim(str) {
        try {
            if (isNull(str) || str.lenght < 1) { return ''; }

            str = str.toString();
            return str.replace(/^\s+|\s+$/g, '');

        } catch (err) {
            // PutToDebugLogException(2, "common: Trim", err);
            console.error('error ' + err);
        }

        return str;
    }

    Start() {
        this.initWebPhone();

        setTimeout(() => {
            //alert('on webphone start... ' + this.ipAsterisk);

            if (typeof (this.username) === 'undefined' || this.username === null || this.username.length < 1) {
                console.error('Set a valid username.');
                return;
            }

            if (typeof (this.password) === 'undefined' || this.password === null || this.password.length < 1) {
                console.error('Set a valid password.');
                return;
            }

            if (typeof (this.ipAsterisk) !== 'undefined' && this.ipAsterisk !== null && this.ipAsterisk.length > 0) {
                webphone_api.setparameter('serveraddress', this.ipAsterisk);
            }

            // webphone_api.setparameter('sipusername', this.username);
            webphone_api.setparameter('transport', 2);
            webphone_api.setparameter('mediaencryption', 0);
            webphone_api.setparameter('username', this.username);
            webphone_api.setparameter('password', this.password);
            // webphone_api.setparameter('destination', this.destination);


            webphone_api.start();
        }, 2000);
    }

    Call(destination) {
        this.destination = destination;


        if (webphone_api.isregistered()) {
            webphone_api.setparameter('destination', this.destination);
            webphone_api.call(this.destination);
        }
    }

    Hangup() {
        webphone_api.hangup();

    }

    Accept() {
        webphone_api.accept()

    }

    Reject() {
        webphone_api.reject();
    }

    Unregister() {
        webphone_api.unregister();
    }

    GetTickCount() {
        var currDate = new Date();
        return currDate.getTime();
    }

    async presentAlertIncoming(peerdisplayname) {
        console.log('targetUser ' + peerdisplayname);
        const alert = await this.alertCtrl.create({
            cssClass: 'alertCustomCss',
            // header: 'Confirm!',
            message: 'Llamada entrante de ' + peerdisplayname,
            buttons: [
                {
                    text: 'Rechazar',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: (blah) => {
                        console.log('Confirm Cancel: blah');
                        this.Reject();
                    }
                }, {
                    text: 'Okay',
                    handler: () => {
                        console.log('Confirm Okay');
                        this.Accept();
                    }
                }
            ]
        });
        await alert.present();
    }

    // Helper function to get an HTML audio element
    getAudioElement(id: string): HTMLAudioElement {
        const el = document.getElementById(id);
        if (!(el instanceof HTMLAudioElement)) {
            throw new Error(`Element "${id}" not found or not an audio element.`);
        }
        return el;
    }

    // Helper function to wait
    async wait(ms: number): Promise<void> {
        return new Promise((resolve) => {
            setTimeout(resolve, ms);
        });
    }

    jssipConnect() {
        // var socket = new JsSIP.WebSocketInterface('ws://' + this.ipAsterisk + ':8088/ws');
        var socket = new JsSIP.WebSocketInterface('ws://' + this.ipAsterisk + ':8088/ws');

        // socket.via_transport = "auto";
        var configuration = {
            sockets: [socket],
            authorization_user: this.username,
            uri: 'sip:' + this.username + '@' + this.ipAsterisk,
            // ws_servers: 'ws://' + this.ipAsterisk + ':8089/ws',
            password: this.password,
            // display_name: username,
            // no_answer_timeout: null,
            // realm: this.ipAsterisk,
            // trace_sip: true,
            // stun_servers: ['stun:null'],
            disableHostCheck: true,
            // turn_servers: null,
            // use_preloaded_route: null,
            // connection_recovery_min_interval: null,
            // connection_recovery_max_interval: null,
            // hack_ip_in_contact: false
        };

        const coolPhone = new JsSIP.UA(configuration);
        coolPhone.on('connected', (e) => { alert('connected') });

        coolPhone.on('disconnected', (e) => { alert('disconnected') });

        coolPhone.on('newRTCSession', (e) => { console.error(e.originator) });

        coolPhone.on('registered', (e) => { alert('registered') });
        coolPhone.on('unregistered', (e) => { alert('unregistered') });
        coolPhone.on('registrationFailed', (e) => { console.error('registration Failed ' + JSON.stringify(e)) });

        coolPhone.start();
        //coolPhone.register();

        // Register callbacks to desired call events
        const eventHandlers = {
            'progress': (e) => {
                console.log('call is in progress');
            },
            'failed': (e) => {
                console.log('call failed with cause: ' + JSON.stringify(e));
            },
            'ended': (e) => {
                console.log('call ended with cause: ' + JSON.stringify(e));
            },
            'confirmed': (e) => {
                console.log('call confirmed');
            }
        };

        const options = {
            'eventHandlers': eventHandlers,
            'mediaConstraints': { 'audio': true, 'video': false }
        };

    }
/*
    async sipConnect(): Promise<void> {
        console.log('Connecting sip... ');

        //const domain = 'voip.mizu-voip.com';
        //const username = 'webphonetest1';
        //const password = 'webphonetest1';
        //const userDestiny = 'webphonetest2';

        const server = 'ws://' + this.ipAsterisk + ':8088/ws';

        // SIP Request URI
        // The SIP Request URI of the destination. It's "Who you wanna call?"
        // SIP is an internet standard the details of which are outside the
        // scope of this documentation, but there are many resources available.
        // See: https://tools.ietf.org/html/rfc3261 for the specification.
        const destination = 'sip:' + this.userDestiny + '@' + this.ipAsterisk;

        // SIP Address of Record (AOR)
        // This is the user's SIP address. It's "Where people can reach you."
        // SIP is an internet standard the details of which are outside the
        // scope of this documentation, but there are many resources available.
        // See: https://tools.ietf.org/html/rfc3261 for the specification.

        // const aor = "sip:webphonetest1@" + domain;
        const aor = 'sip:' + this.username + '@' + this.ipAsterisk;


        // SIP Authorization Username
        // This is the user's authorization username used for authorizing requests.
        // SIP is an internet standard the details of which are outside the
        // scope of this documentation, but there are many resources available.
        // See: https://tools.ietf.org/html/rfc3261 for the specification.
        const authorizationUsername = this.username;

        // SIP Authorization Password
        // This is the user's authorization password used for authorizing requests.
        // SIP is an internet standard the details of which are outside the
        // scope of this documentation, but there are many resources available.
        // See: https://tools.ietf.org/html/rfc3261 for the specification.
        const authorizationPassword = this.password;

        // alert('PASSWORD ' + authorizationPassword)

        const options: Web.SimpleUserOptions = {
            aor,
            media: {
                remote: {
                    audio: this.getAudioElement("remoteAudio")
                }
            }, userAgentOptions: {
                authorizationUsername,
                authorizationPassword,
            }
        };

        // Construct a SimpleUser instance
        const simpleUser = new Web.SimpleUser(server, options);

        // Supply delegate to handle inbound calls (optional)
        simpleUser.delegate = {
            onCallReceived: async () => {
                alert('ON CALL RECEIVED');
                await simpleUser.answer();
            }
        };

        simpleUser.delegate = {
            onRegistered: async () => {
                alert('ON REGISTERED');

            }
        };

        simpleUser.delegate = {
            onServerConnect: async () => {
                alert('ON SERVER CONNECT');

                // Register to receive inbound calls (optional)
                await simpleUser.register().then(result => {

                    alert('Resutl register + ' + JSON.stringify(result) + ', calling... ');

                    simpleUser.call(destination);

                    // console.log('aqui orders ' + JSON.stringify( orders ));

                });
            }
        };

        // Connect to server
        await simpleUser.connect();
        // alert('connected');

        // Place call to the destination
        // await simpleUser.call(destination);
        // alert('after call ');
        // Wait some number of milliseconds
        // await this.wait(5000);

        // Hangup call
        // await simpleUser.hangup();



    }*/
}
