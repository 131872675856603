import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'license-manager', pathMatch: 'full' },
 // { path: 'home2', loadChildren: './home/home2.module#HomePageModule' },
  { path: 'product-details-page', loadChildren: './product-details-page/product-details-page.module#ProductDetailsPagePageModule' },
  { path: 'cart-page', loadChildren: './cart-page/cart-page.module#CartPagePageModule' },
  { path: 'license-manager', loadChildren: './license-manager/license-manager.module#LicenseManagerPageModule' },
  { path: 'link', loadChildren: './link/link.module#LinkPageModule' },
  { path: 'survey', loadChildren: './survey/survey.module#SurveyPageModule' },
  { path: 'networks', loadChildren: './networks/networks.module#NetworksPageModule' },
  { path: 'standby', loadChildren: './standby/standby.module#StandbyPageModule' },
  { path: 'modal', loadChildren: './modal/modal.module#ModalPageModule' },
  { path: 'sleep', loadChildren: './sleep/sleep.module#SleepPageModule' },
  { path: 'home', loadChildren: './home/home.module#HomePageModule' },  { path: 'map', loadChildren: './map/map.module#MapPageModule' },
  { path: 'maintenance', loadChildren: './maintenance/maintenance.module#MaintenancePageModule' },
  { path: 'interactive-standby', loadChildren: './interactive-standby/interactive-standby.module#InteractiveStandbyPageModule' },





];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
